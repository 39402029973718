<template>
  <v-dialog v-model="modalStatus" max-width="220px">
    <v-card height="28vh">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <vueDropzone
        id="dropzoneImages"
        ref="myVueDropzone"
        :options="imagesOptions"
        @vdropzone-thumbnail="uploadFile"
        @vdropzone-error="failed"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vue2Dropzone from 'vue2-dropzone';
import { eventBus } from '../../../main'
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'changeAvatar',

  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      show: false,
      imagesOptions: {
        autoProcessQueue: false,
        url: '#',
        maxFilesize: 2.5, // MB
        maxFiles: 1,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
        dictDefaultMessage: "<i class='fa fa-user' style='font-size:2em;'> </i><br> Mudar foto de perfil",
      },
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      modalStatus: 'user/showModal',
    }),
  },

  methods: {
    ...mapActions('user', ['saveUser', 'changeAvatar', 'changeModal']),

    async uploadFile(file) {
      let dataURL = file.dataURL;
      let base64ForUpload = dataURL.split(',')[1];
      let fileToUpload = {
        name: file.name,
        user: this.userInfo.username,
        estab: '096259230003',
        company: '09625923',
        userid: this.userInfo.id,
        clienteid: 1232123123,
        visibility: false,
        base64: base64ForUpload,
      };

      await this.changeAvatar(fileToUpload).then(() => {
          this.$refs.myVueDropzone.removeFile(file);
          this.showToast('Avatar alterado com sucesso.', 'success', 78);
      });

      


    },

    showToast(message, type, topToast) {
      let toast = {
        show : true,
        type : type,
        message : message,
        topToast: topToast
      }
      eventBus.$emit('openToast', toast);
    },

    close() {
      this.changeModal(false);
    },

    failed() {
      let parse = { message: 'Falha no servidor, tente em alguns minutos' };
      const elements = document.querySelectorAll('.dz-error-message span');
      const lastElement = elements[elements.length - 1];
      lastElement.textContent = parse.message;
    },
  },
};
</script>
<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
</style>
