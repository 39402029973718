var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "220px" },
      model: {
        value: _vm.modalStatus,
        callback: function ($$v) {
          _vm.modalStatus = $$v
        },
        expression: "modalStatus",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { height: "28vh" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c("vueDropzone", {
            ref: "myVueDropzone",
            attrs: { id: "dropzoneImages", options: _vm.imagesOptions },
            on: {
              "vdropzone-thumbnail": _vm.uploadFile,
              "vdropzone-error": _vm.failed,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }